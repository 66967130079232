<template>
  <div style="background-color: #FFC107; height: 50px; width: 50px;" class="rounded-full border-4 border-white overflow-hidden">
    <label for="files" class="mt-4">
        <img :src="img" class="relative" style="top: 10px" />
    </label>
    <div class="relative">
        <input id="files" type="file" style="visibility:hidden;"  @change="uploadFile">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: String,
    height: String
  },
  computed: {
    img () {
      return this.company.MascotImages.Avatar
    }
  }
}
</script>

<style>
</style>
